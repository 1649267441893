<template>
  <div class="DoctorList">

    <van-nav-bar
      @click-left="onClickLeft"
      left-text="返回"
      left-arrow
    />

    <van-form @submit="onSubmit">

      <van-field
        required
        v-model="doctor.name"
        label="医生姓名"
        placeholder="请输入医生姓名"
        :rules="[{ required: true }]"
      />
      <van-field
        required
        v-model="doctor.hospital"
        label="医院"
        placeholder="请输入医院"
        :rules="[{ required: true }]"
      />
      <van-field
        required
        v-model="doctor.department"
        label="科室"
        placeholder="请输入科室"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="doctor.mobile"
        type="phone"
        label="手机号"
        placeholder="请输入手机号"
      />

      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>

  </div>

</template>

<script>
import { Toast } from 'vant'
import { createDoctorApi } from '@/api/user'

export default {
  name: 'DoctorAdd',
  metaInfo: {
    title: '添加医生',
  },
  created() {
    this.initUser()
    // this.getSamples({ openid: this.userInfo.openid })

  },
  data() {
    return {
      doctor: {
        name: '',
        hospital: '',
        department: '',
        mobile: '',
        user_id: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.doctor.user_id = this.userInfo.id
      // this.doctor.user_id = 7
      createDoctorApi(this.doctor).then(res => {
        Toast.success('创建成功')
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style scoped>

</style>
